<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Blog Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Title"
          :danger="validation.title ? true : false"
          :danger-text="validation.title ? validation.title[0] : ''"
          :value="form.title"
          @input="updateForm({ key: 'title', value: $event })"
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Slug"
          :danger="validation.slug ? true : false"
          :danger-text="validation.slug ? validation.slug[0] : ''"
          :value="form.slug"
          @input="updateForm({ key: 'slug', value: $event })"
        />
      </div>
      <div class="vx-col w-full mt-4 md:w-2/3">
        <label class="vs-input--label">Category</label>
        <v-select
          :danger="validation.blog_category_id ? true : false"
          :value="dropdown.blog_category_id"
          @input="updateForm({ key: 'blog_category_id', value: $event })"
          :options="categories"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.blog_category_id ? true : false"
        >
          {{
            validation.blog_category_id ? validation.blog_category_id[0] : ""
          }}
        </span>
      </div>
      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Position"
          :danger="validation.position ? true : false"
          :danger-text="validation.position ? validation.position[0] : ''"
          :value="form.position"
          @input="updateForm({ key: 'position', value: $event })"
        />

        <span
          class="text-danger text-sm"
          v-show="validation.position ? true : false"
        >
          {{
            validation.position ? validation.position[0] : ""
          }}
        </span>
      </div>
    </div>
    <div class="w-full mt-4">
      <label for="Short Description">Short Description</label>
      <quill-editor
        :options="editorOptions"
        :value="form.short_description"
        @input="updateForm({ key: 'short_description', value: $event })"
      ></quill-editor>
      <span
        class="text-danger text-sm"
        v-show="validation.short_description ? true : false"
      >
        {{
          validation.short_description ? validation.short_description[0] : ""
        }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="Content">Content</label>
      <quill-editor
        :options="editorOptions"
        :value="form.description"
        @input="updateForm({ key: 'description', value: $event })"
      ></quill-editor>
      <span
        class="text-danger text-sm"
        v-show="validation.description ? true : false"
      >
        {{ validation.description ? validation.description[0] : "" }}
      </span>
    </div>
  </div>
</template>

<script>
import vSelect, { vOption } from "vue-select";
import { mapGetters, mapActions } from "vuex";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { Quill, quillEditor } from "vue-quill-editor";
import ImageUploader from "quill-image-uploader/src/quill.imageUploader";
import { uploadContentImage } from "@/services/image";
import htmlEditButton from "quill-html-edit-button";

Quill.register({
  "modules/imageUploader": ImageUploader,
  "modules/htmlEditButton": htmlEditButton,
});
const fullToolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];

export default {
  data() {
    return {
      editorOptions: {
        theme: "bubble",
        modules: {
          toolbar: fullToolbarOptions,
          htmlEditButton: {},
          // image uploader
          imageUploader: {
            // eslint-disable-next-line no-unused-vars
            upload: (file) => {
              return new Promise((resolve, reject) => {
                uploadContentImage(file)
                  .then((result) => {
                    console.log(result);
                    resolve(result.data.url);
                  })
                  .catch((error) => {
                    reject("Upload failed");
                    console.error("Error:", error);
                  });
              });
            },
          },
        },
      },
    };
  },
  components: {
    vSelect,
    vOption,
    quillEditor,
  },
  computed: {
    ...mapGetters({
      form: "blog/getFormObj",
      categories: "blog/getBlogCategories",
      dropdown: "blog/getDropdownObj",
      validation: "validation/getValidationErrors",
    }),
  },
  methods: {
    ...mapActions({
      updateForm: "blog/updateForm",
      fetchAndSetCategories: "blog/fetchAndSetCategories",
    }),
  },
  created() {
    this.fetchAndSetCategories();
  },
};
</script>
