<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Blogs Related Package, Hotes and Homestays</span>
    <vs-collapse>
        <vs-collapse-item>
            <div slot="header">Packages</div>
            <div v-for="(p, index) in packages" :key="index" class="flex items-center mb-4">
                <vs-checkbox :value="form.packages[p.id]" @input="updateForm({key: 'packages', value: {event: $event, value: p.id}})" />
                <span class="ml-4">{{p.name}}</span>
            </div>
        </vs-collapse-item>

        <vs-collapse-item>
            <div slot="header">Homestays</div>
            <div v-for="(homestay, key) in homestays" :key="key" class="flex items-center mb-4">
                <vs-checkbox :value="form.homestays[homestay.id]" @input="updateForm({key: 'homestays', value: {event: $event, value: homestay.id}})" />
                <span class="ml-4">{{homestay.name}}</span>
            </div>
        </vs-collapse-item>

        <vs-collapse-item>
            <div slot="header">Hotels</div>
            <div v-for="(hotel, hKey) in hotels" :key="hKey" class="flex items-center mb-4">
                <vs-checkbox :value="form.hotels[hotel.id]" @input="updateForm({key: 'hotels', value: {event: $event, value: hotel.id}})" />
                <span class="ml-4">{{hotel.name}}</span>
            </div>
        </vs-collapse-item>
    </vs-collapse>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
        false: ''
    }
  },
  computed: {
    ...mapGetters({
        form: 'blog/getFormObj',
        packages: 'blog/getPackages',
        homestays: 'blog/getHomestays',
        hotels: 'blog/getHotels',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'blog/updateForm',
      fetchAndSetPackages: 'blog/fetchAndSetPackages',
      fetchAndSetHomestays: 'blog/fetchAndSetHomestays',
      fetchAndSetHotels: 'blog/fetchAndSetHotels',
    })
  },
  created(){
    this.fetchAndSetPackages();
    this.fetchAndSetHomestays();
    this.fetchAndSetHotels();
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
